import { BUILD_TIMESTAMP } from '@environments/timestamp';

const packageJson = require('../../package.json');

export const environment = {
  appName: 'Marginpar DXP',
  production: false,
  hmr: false,
  name: 'Acceptatie',
  baseUrl: 'https://marginpar-acceptance.staging.emendis.nl/api',
  gitVersion: '1.10.18-22-gee76e98',
  sentryUrl: 'https://ebfa48682afc4dba8603ec1d5a264046@o4504360297496576.ingest.sentry.io/4504400505405440',
  databaseName: 'emendis.marginpar',
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  redirectAfterLogin: '/',
  parseMissingTranslations: false,
  currentHash: '{{POST_BUILD_ENTERS_HASH_HERE}}',
  url: 'https://marginpar.complete.acceptatie.emendis.nl',
};
